// TopContents.js
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Config from '../Utils/Config';
import './TopContents.css';

function TopContents() {
    let content;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // 自動再生を有効にする
        autoplaySpeed: 5000, // 3秒ごとにスライドを切り替える
    };

    switch (Config.topContentPattern) {
        case 'pattern1':
            content = (
                <Slider {...settings}>
                    <div>
                    <img src="/images/top1.png" alt="Top 1" />
                    </div>
                    <div>
                    <img src="/images/top2.png" alt="Top 2" />
                    </div>
                    <div>
                    <img src="/images/top3.png" alt="Top 3" />
                    </div>
                </Slider>
            );
            break;
            case 'pattern2':
                content = (
                    <Slider {...settings}>
                        {/* 例: 影付きのスライダー */}
                        {/* 各スライドごとに影と画像を配置 */}
                    </Slider>
                );
                break;
                case 'pattern3':
                    content = (
                        <div className="dark-background">
                            {/* テキストと動画/画像の組み合わせ */}
                            {/* スライドまたはアニメーションで切り替わる内容 */}
                        </div>
                    );
                    break;
                    default:
                        content = <div>デフォルトのコンテンツ</div>;
                }

    return <div className="top-contents">{content}</div>;
}

export default TopContents;

// MoreAboutContents.js
import React from 'react';
import './MoreAboutContents.css';

function MoreAboutContents() {
    // サンプルデータを用いますが、実際にはAPIからデータを取得したり、
    // プロップス経由でデータを受け取ることができます。
    const galleryItems = [
        { id: 1, image: 'images/coffeemore1.jpg', title: '淹れたてコーヒー' },
        { id: 2, image: 'images/coffeemore2.jpg', title: '上質なコーヒー豆' },
        { id: 3, image: 'images/coffeemore3.jpg', title: 'プレミアムな香り' },
    ];

    return (
        <div className="more-about-contents">
            {galleryItems.map(item => (
                <div key={item.id} className="gallery-item">
                    <img src={item.image} alt={item.title} />
                    <h3>{item.title}</h3>
                </div>
            ))}
        </div>
    );
}

export default MoreAboutContents;

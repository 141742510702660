// AboutContents.js
import React from 'react';
import './AboutContents.css';

function AboutContents() {
    return (
        <div className="about-contents">
            <div className="about-text-container">
                <div className="about-text">
                    <h2>ABOUT</h2>
                    <p className="main-copy">淹れたてのコーヒー、その一杯に込められた物語</p>
                    <p className="sub-copy">淹れたてのコーヒーは単なる飲み物ではありません。それは、選び抜かれた豆の旅路、熟練の技、そして瞬間を楽しむための一時の贅沢です。新鮮な香り、豊かな味わい、心地よい温もりを、一杯の中に閉じ込めました。コーヒーを淹れるたび、新たな物語が生まれます。</p>
                    </div>

                    <button className="stylish-button">Lean More</button>
            </div>
            <div className="about-image">
                <img src="/images/about.png" alt="About Us" />
            </div>
        </div>
    );
}

export default AboutContents;

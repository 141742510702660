// Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="site-footer">
            <div className="footer-content">
                {/* <p>住所: 123 アドレス ストリート, シティ, 国</p> */}
                <p><a href="/sitemap">サイトマップ</a></p>
                <p><a href="/contact">お問い合わせ</a></p>
                {/* SNSリンク */}
                <div className="sns-links">
                    <a href="https://www.facebook.com/">Facebook</a>
                    <a href="https://www.twitter.com/">Twitter</a>
                    <a href="https://www.instagram.com/">Instagram</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

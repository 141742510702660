// GalleryContents.js
import React, { useState } from 'react';
import './GalleryContents.css';

function GalleryContents() {
    const [hoveredStep, setHoveredStep] = useState(null);

    const galleryImages = [
        '/images/coffeemore1.jpg',
        '/images/coffeemore2.jpg',
        '/images/coffeemore3.jpg',
        '/images/coffee1.png', 
    ];

    return (
        <div className="gallery-contents">
            <h2>UCHI COFFEE</h2>
            <div className="gallery-steps">
                {galleryImages.map((image, index) => (
                    <React.Fragment key={index}>
                    <div 
                        className="step" 
                        onMouseEnter={() => setHoveredStep(index)} // ここを変更しました
                        onMouseLeave={() => setHoveredStep(null)}
                    >           
                        <img src={image} alt={`Step ${index + 1}`} />
                        <p className="step-number">ステップ {index + 1}</p>
                    </div>
                    {index < galleryImages.length - 1 && (
                        <div className={`arrow ${hoveredStep === index ? 'hovered' : ''}`}>→</div>
                    )}
                </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default GalleryContents;


// PickupContents.js
import React, { useState } from 'react';
import './PickupContents.css';

function PickupContents() {
    // サンプル商品データ
    const products = [
        { id: 1, name: '商品名1', image: '/images/coffee1.png', price: '¥1,000' },
        { id: 2, name: '商品名1', image: '/images/coffee2.png', price: '¥1,000' },
        { id: 3, name: '商品名1', image: '/images/coffee3.png', price: '¥1,000' },
        { id: 4, name: '商品名1', image: '/images/coffee4.png', price: '¥1,000' },
    ];

        // アニメーションの状態を管理するための状態変数
        const [isAnimating, setIsAnimating] = useState(null);

        const handleMouseOver = (id) => {
            setIsAnimating(id);
        };
    
        const handleMouseOut = () => {
            setIsAnimating(null);
        };

        return (
            <div className="pickup-contents">
                {products.map(product => (
                    <div key={product.id} className="product-card"
                         onMouseOver={() => handleMouseOver(product.id)}
                         onMouseOut={handleMouseOut}>
                        <div className="animation-layer">
                            {isAnimating === product.id 
                                ? <img src="/images/coffeeanime.gif" alt="Coffee Animation" />
                                : <img src="/images/coffeeanime.png" alt="Static Coffee" />
                            }
                        </div>
                        <div className="content-layer">
                            <img src={product.image} alt={product.name} className="product-image" />
                            <h3 className="product-name">{product.name}</h3>
                            <p className="product-price">{product.price}</p>
                            <button className="view-shop-button">ショップを見る</button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

export default PickupContents;

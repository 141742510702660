import React from 'react';
import './MainmenuContents.css';

function MainmenuContents() {
    // サンプルデータの更新
    const menuItems = [
        { id: 1, image: '/images/coffee1.png', title: '商品名1', price: '¥1000', description: '説明テキスト1' },
        { id: 2, image: '/images/coffee2.png', title: '商品名2', price: '¥1200', description: '説明テキスト2' },
        { id: 3, image: '/images/coffee3.png', title: '商品名3', price: '¥1500', description: '説明テキスト3' }
    ];

    const sectionStyle = {
        backgroundImage: `url(/images/coffeemore3.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed' /* パララックス効果を適用 */
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // 白基調の半透明オーバーレイ
        zIndex: 0 // 背景の後ろに配置
    };

    const contentStyle = {
        position: 'relative',
        zIndex: 2 // オーバーレイよりも前に配置
    };

    return (
        <div className="mainmenu-contents" style={sectionStyle}>
            <div style={overlayStyle}></div>
            <div style={contentStyle}>
            <h2>MENU</h2>
            <p className="main-text">一口で旅する世界の味。あなたを魅了する、深い香りと豊かな味わいのコーヒー。</p>
            {menuItems.map((item, index) => (
                <div key={item.id} className={`menu-item ${index % 2 === 0 ? 'normal' : 'reverse'}`}>
                    <div className="item-image">
                        <img src={item.image} alt={item.title} />
                    </div>
                    <div className="item-details">
                        <h3>{item.title}</h3>
                        <p className="price">{item.price}</p>
                        <p className="description">{item.description}</p>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
}

export default MainmenuContents;

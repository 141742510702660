// IntroductionContents.js
import React from 'react';
import './IntroductionContents.css';

function IntroductionContents() {
    return (
        <div className="introduction-contents">
            <div className="introduction-text">
                <h2>INTRODUCTION</h2>
                <p>ここにメインコピーを記載。</p>
            </div>
            <div className="introduction-image">
            <video src="/images/drip.mp4" alt="Introduction" autoPlay muted loop />
            </div>
        </div>
    );
}

export default IntroductionContents;

import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Header from './components/Header';
import TopContents from './components/TopContents';
import PickupContents from './components/PickupContents';
import AboutContents from './components/AboutContents';
import MoreAboutContents from './components/MoreAboutContents';
import MainmenuContents from './components/MainmenuContents';
import ProductContents from './components/ProductContents';
import IntroductionContents from './components/IntroductionContents';
import GalleryContents from './components/GalleryContents';
import InformationContents from './components/InformationContents';
import Footer from './components/Footer';

import usePageTracking from './hooks/usePageTracking';

import './App.css';

function App() {
    usePageTracking();
    const fadeInLeft = {
        hidden: { y: 30, opacity: 0 },
        visible: i => ({
            y: 0,
            opacity: 1,
            transition: {
                delay: i * 0.1,
                type: "spring",
                stiffness: 50,
                damping: 20,  // ダンピングを増やして、動きをより滑らかにします
                mass: 1       // 質量を調整して、動きのリアリティを増加
            }
        })
    };

    const [topContentsRef, topContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [pickupContentsRef, pickupContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [aboutContentsRef, aboutContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [moreAboutContentsRef, moreAboutContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [mainmenuContentsRef, mainmenuContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [productContentsRef, productContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [introductionContentsRef, introductionContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [galleryContentsRef, galleryContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [informationContentsRef, informationContentsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [footerRef, footerInView] = useInView({ triggerOnce: true, threshold: 0.1 });

return (
    <div className="App">
            <Header />

        <motion.div ref={topContentsRef} custom={1} initial="hidden" animate={topContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <TopContents />
        </motion.div>

        <motion.div ref={pickupContentsRef} custom={2} initial="hidden" animate={pickupContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <PickupContents />
        </motion.div>

        <motion.div ref={aboutContentsRef} custom={3} initial="hidden" animate={aboutContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <AboutContents />
        </motion.div>

        <motion.div ref={moreAboutContentsRef} custom={4} initial="hidden" animate={moreAboutContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <MoreAboutContents />
        </motion.div>

        <motion.div ref={mainmenuContentsRef} custom={5} initial="hidden" animate={mainmenuContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <MainmenuContents />
        </motion.div>

        <motion.div ref={productContentsRef} custom={6} initial="hidden" animate={productContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <ProductContents />
        </motion.div>

        <motion.div ref={introductionContentsRef} custom={7} initial="hidden" animate={introductionContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <IntroductionContents />
        </motion.div>

        <motion.div ref={galleryContentsRef} custom={8} initial="hidden" animate={galleryContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <GalleryContents />
        </motion.div>

        <motion.div ref={informationContentsRef} custom={9} initial="hidden" animate={informationContentsInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <InformationContents />
        </motion.div>

        <motion.div ref={footerRef} custom={10} initial="hidden" animate={footerInView ? "visible" : "hidden"} variants={fadeInLeft} transition={{ duration: 2.5 }}>
            <Footer />
        </motion.div>
    </div>
);
    
}

export default App;

import React, { useState, useEffect } from 'react';
import './InformationContents.css';

function InformationContents() {
    const [blogPosts, setBlogPosts] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        fetch('https://since-around4.com/wp-json/wp/v2/posts')
            .then(response => response.json())
            .then(data => {
                const posts = data.slice(0, 5).map(post => ({
                    id: post.id,
                    title: post.title.rendered,
                    date: formatDate(post.date) // 日付のフォーマット変更
                }));
                setBlogPosts(posts);
            });

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // 日付を YYYY.MM.DD 形式にフォーマットする関数
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
    };

    return (
        <div className="blog-posts">
            <div className="information-text">
                <h2>INFORMATION</h2>
            </div>
            {blogPosts.map(post => (
                <div key={post.id} className="blog-post">
                    <div className="blog-post-content">
                        <h3 className="blog-post-title">{post.title}</h3>
                        <p className="blog-post-date">{post.date}</p>
                    </div>
                    <div className="blog-post-more">
                        <button className="more-button">{isMobile ? '→' : 'MORE'}</button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default InformationContents;

// Header.js
import React, { useState } from 'react';
import './Header.css'; // スタイルシート

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            <div className="logo">
            <img src="/images/logo.png" alt="Top 1" />
            </div>
            <nav className={isMenuOpen ? 'active' : ''}>
                <ul>
                    {/* メニュー項目 */}
                    <li><a href="#home">HOME</a></li>
                    <li><a href="#about">ABOUT</a></li>
                    <li><a href="#contact">CONTACT</a></li>
                </ul>
            </nav>
            <div className="sns-links">
                {/* SNSリンク */}
            </div>
            <div className={`hamburger ${isMenuOpen ? 'open' :
                ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </header>
    );
}

export default Header;

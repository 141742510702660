import React, { useState } from 'react';
import './ProductContents.css';

function ProductContents() {
    const [showCircle, setShowCircle] = useState(true);

    // 画像データを配列で保持
    const images = [
        '/images/coffeemore1.jpg',
        '/images/coffeemore2.jpg',
        '/images/coffeemore3.jpg',
        '/images/coffee1.png', // 画像ファイル名が重複していたので変更しました
    ];

    const overlayTitles = ["フェアトレード", "オーガニック", "受注後焙煎", "限定商品"];

    const handleMouseEnter = () => setShowCircle(false);
    const handleMouseLeave = () => setShowCircle(true);

    return (
        <div className="product-contents">
            <div className="left-container">
            <div className="product-text-area">
            <h1 className="title">PRODUCT</h1>
            <p className="main-text">フェアトレードやオーガニックにこだわり、ここでしか手に入らない限定品もお届けします。各一杯が上質でプレミアムな味わいです。</p>
            <p className="sub-text">お客様の一杯が世界を変える一歩に。当店のフェアトレードコーヒーは、遠く離れた地域の生産者たちを直接サポート。公正な取引で彼らの生活を向上させ、持続可能な農業を推進しています。地球に優しいオーガニック製法で栽培されたコーヒー豆は、深い味わいと豊かな香りをお届けします。また、当店の限定品は、ここでしか味わえない特別な一杯を提供します。さらに、お客様の注文を受けてから焙煎することで、新鮮で質の高いコーヒー体験を実現。私たちの情熱と品質へのこだわりを、各一杯に込めています。</p>
            </div>
            </div>
            <div className="right-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="image-area">
                    {images.map((image, index) => (
                        <div key={index} className={`frame frame-${index + 1}`}>
                            <img src={image} alt={`Product ${index + 1}`} />
                            <div className="frame-overlay">{overlayTitles[index]}</div>
                        </div>
                    ))}
                    <div className={`circle ${showCircle ? '' : 'hidden'}`}>
                        <span className="circle-text">Learn More</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductContents;
